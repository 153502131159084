<template>
  <section>
    <hr />
    <span
      class="btn btn-default pull-left"
      data-testid="remove"
      v-bind:disabled="busy"
      v-if="remove && can"
      v-on:click.prevent.stop="onButtonRemoveClick"
    >
      <i class="fa fa-trash"></i>
      {{ $t("delete") }}
    </span>
    <div class="text-right">
      <!-- checkbox option for clonning -->
      <div
        class="btn btn-sm btn-clone"
        v-if="clone && showClone && can"
        v-on:click.prevent.stop="toggleClone"
        v-bind:disabled="!isValid"
      >
        <label class="checkbox-inline">
          <input type="checkbox" v-model="isCloning" />
          {{ $t("create_copy") }}
        </label>
      </div>

      <span
        class="btn btn-default"
        data-testid="cancel"
        v-on:click.prevent.stop="onButtonCancelClick"
        style="margin-right:10px;"
      >
        {{ $t("cancel") }}
      </span>

      <!-- checkbox option for clonning -->
      <span
        v-if="can && isCloning"
        class="btn btn-primary"
        data-testid="clone"
        v-on:click.prevent.stop="onButtonCloneClick"
        v-bind:disabled="!isValid"
      >
        <i v-if="busy" class="fa fa-refresh fa-spin"></i>
        <i v-else class="fa fa-copy"></i>
        {{ $t("clone") }}
      </span>

      <div class="btn-group" v-if="can && !isCloning && addAnother">
        <span
          type="button"
          class="btn btn-primary"
          data-testid="save"
          v-on:click.prevent.stop="onButtonSaveAndExitClick"
          v-bind:disabled="!isValid"
        >
          <i v-if="busy" class="fa fa-refresh fa-spin"></i>
          <i v-else class="fa fa-check"></i>
          {{ $t("save") }}
        </span>
        <span
          type="button"
          class="btn btn-primary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          v-bind:disabled="!isValid"
        >
          <span class="caret"></span>
          <span class="sr-only">Toggle Dropdown</span>
        </span>
        <ul class="dropdown-menu">
          <li>
            <a href="#" v-on:click.prevent.stop="onButtonSaveClick">{{
              $t("save_and_continue")
            }}</a>
          </li>
          <li>
            <a href="#" v-on:click.prevent.stop="onButtonSaveAndAddAnother">{{
              $t("save_and_add_new")
            }}</a>
          </li>
        </ul>
      </div>
      <span
        v-else-if="can && !isCloning"
        type="button"
        class="btn btn-primary"
        data-testid="save"
        v-on:click.prevent.stop="onButtonSaveAndExitClick"
        v-bind:disabled="!isValid"
      >
        <i v-if="busy" class="fa fa-refresh fa-spin"></i>
        <i v-else class="fa fa-check"></i>
        {{ $t("save") }}
      </span>
    </div>
  </section>
</template>

<script>
export default {
  name: "FormFooterToolbar",
  props: {
    busy: {
      type: Boolean,
      required: false,
      default: false
    },
    valid: {
      type: Boolean,
      required: false,
      default: false
    },
    clone: {
      type: Boolean,
      required: false,
      default: false
    },
    remove: {
      type: Boolean,
      required: false,
      default: true
    },
    addAnother: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    rule: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  data() {
    return {
      isCloning: false,
      showClone: false // todo: to be validated
    };
  },
  computed: {
    can() {
      return !this.rule || this.$can("manage", this.rule);
    },
    isValid() {
      return this.valid && !this.busy;
    }
  },
  methods: {
    toggleClone() {
      this.isCloning = !this.isCloning;
      this.$emit("input", this.isCloning);
    },
    onButtonCancelClick() {
      this.$emit("buttonCancelClick");
    },
    onButtonCloneClick() {
      this.$emit("buttonCloneClick");
    },
    onButtonSaveClick() {
      this.$emit("buttonSaveClick", true);
    },
    onButtonSaveAndExitClick() {
      this.$emit("buttonSaveClick", false);
    },
    onButtonSaveAndAddAnother() {
      this.$emit("buttonSaveClick", true, true);
    },
    onButtonRemoveClick() {
      this.$emit("buttonRemoveClick");
    }
  },
  mounted() {
    if (this.clone) {
      this.isCloning = this.value;
      //$(".dropdown-toggle").dropdown();
    }
  }
};
</script>

<style scoped>
.btn-clone {
  margin: 0 50px;
}
.btn-save {
  position: relative;
  display: inline-block;
}
</style>
