<template>
  <section>
    <div class="nav-tabs-custom">
      <ul class="nav nav-tabs">
        <li class="active">
          <a href="#tab_1" data-toggle="tab"
            ><i class="fa fa-info-circle"></i> {{ $t("identification") }}</a
          >
        </li>
        <li>
          <a href="#tab_2" data-toggle="tab">
            <i class="fa fa-tags"></i>
            {{ $t("tags") }}</a
          >
        </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane active" id="tab_1">
          <div class="row">
            <div class="col-md-5 col-sm-4 col-xs-8">
              <div
                class="form-group"
                :class="{ 'has-error': $attrs.errors && $attrs.errors.name }"
              >
                <label for="item_name" class="noselect">
                  {{ $t("name") }}
                  <ToolTip :title="$t('hints.name')" />
                </label>
                <input
                  type="text"
                  name="item_name"
                  class="form-control"
                  data-testid="name"
                  required
                  v-bind:disabled="isModelBased || $attrs.disabled"
                  v-model="value.name"
                  ref="inpname"
                  autocomplete="off"
                  :title="$attrs.errors && $t($attrs.errors.name)"
                />
              </div>
            </div>
            <div class="hidden-lg hidden-md hidden-sm col-xs-4 text-center">
              <label class="noselect">{{ $tc("enabled") }}?</label>
              <div>
                <FormInputToggle
                  v-bind:labels="$t('yes') + ',' + $t('no')"
                  colors="#119056,#a0a0a0"
                  fontSize="18"
                  v-model="item_enabled"
                ></FormInputToggle>
                <!-- :disabled="$attrs.disabled" -->
              </div>
              <!-- <div class="hide-lg hide-md" style="margin-bottom:20px;" /> -->
            </div>
            <div class="col-md-5 col-sm-5 col-xs-12">
              <div class="form-group">
                <label for="item_description" class="noselect">
                  {{ $t("description") }}
                  <ToolTip :title="$t('hints.name')" />
                </label>
                <input
                  type="text"
                  name="item_description"
                  class="form-control"
                  data-testid="description"
                  v-bind:disabled="isModelBased || $attrs.disabled"
                  v-model="value.description"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="col-md-2 col-sm-3 hidden-xs text-center">
              <label class="noselect">{{ $tc("enabled") }}?</label>
              <div>
                <FormInputToggle
                  v-bind:labels="$t('yes') + ',' + $t('no')"
                  colors="#119056,#a0a0a0"
                  fontSize="18"
                  v-model="item_enabled"
                />
                <!-- :disabled="$attrs.disabled" -->
              </div>
              <!-- <div class="hide-lg hide-md" style="margin-bottom:20px;" /> -->
            </div>
          </div>
        </div>

        <div class="tab-pane" id="tab_2">
          <div class="row">
            <div class="col-md-6">
              <TagsForm v-model="tags" />
            </div>
            <div class="col-md-6">
              <ResourceField :value="value" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
import FormInputToggle from "@/components/registration/form-input-toggle";
import messages from "@/i18n/connector";
import TagsForm from "@/components/registration/tags-form.vue";
import ResourceField from "@/components/registration/resource-field.vue";
export default {
  name: "FormCommonFieldsInline",
  components: {
    ToolTip,
    FormInputToggle,
    TagsForm,
    ResourceField
  },
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {
        return { name: "", description: "", enabled: true };
      }
    },
    isModelBased: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      previous_name: ""
    };
  },
  computed: {
    item_enabled: {
      get() {
        return this.value.enabled ? "1" : "0";
      },
      set(value) {
        this.value.enabled = value == "1" ? true : false;
        this.$emit("input", this.value);
      }
    },
    info() {
      return (
        this.previous_name &&
        this.previous_name != this.item_name &&
        ("connector" in this.value || "device" in this.value)
      );
    },
    tags: {
      set(value) {
        this.value.portal_data = this.value.portal_data || {};
        this.$set(this.value.portal_data, "tags", value);
        this.$emit("input", this.value);
      },
      get() {
        return this?.value?.portal_data?.tags || [];
      }
    }
  },
  i18n: {
    messages
  },
  mounted() {
    this.previous_name = this?.value?.name || "";
    if (this?.$refs?.inpname) {
      this.$refs.inpname.focus();
    }
  }
};
</script>

<style scoped>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.nav > li > a {
  padding: 5px 5px 10px 5px;
  min-width: 60px;
  text-align: center;
  text-transform: uppercase;
}

div.nav-tabs-custom > ul > li.active > a {
  color: #337ab7;
  font-weight: 600;
}
</style>
